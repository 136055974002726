<template>
    <MainAppContainer>
        <businessPage />
        <DownloadApp />
    </MainAppContainer>
</template>
<script>
import MainAppContainer from '@/components/containers/MainAppContainer.vue'
import businessPage from '@/components/pages/businessPage.vue'
import DownloadApp from '@/components/common/DownloadApp'
export default {
    components: {
        MainAppContainer,
        businessPage,
        DownloadApp
    }
}
</script>