<template>
    <div>
        <section class="bannerSection">
            <div class="container">
                <div class="row bannerBlock">
                    <div class="col-lg-6">
                        <div class="row bullet">
                                <div class="col-3">
                                    <div class="d-flex align-items-baseline dotBullet">
                                        <fa :icon="['fas', 'circle']" />
                                        <p> Salons</p>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="d-flex align-items-baseline dotBullet">
                                        <fa :icon="['fas', 'circle']" />
                                        <p> Barbershops</p>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="d-flex align-items-baseline dotBullet">
                                        <fa :icon="['fas', 'circle']" />
                                        <p> Wellness Professionals</p>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-sm-6">
                                    <div class="d-flex align-items-baseline dotBullet">
                                        <fa :icon="['fas', 'circle']" />
                                        <p> Independent Beauty Professionals</p>
                                    </div>
                                </div>
                        </div>

                        <div class="row justify-content-lg-start justify-content-md-center">
                            <div class="col-12 my-3">
                                <p class="heading"> {{businessData.title}} </p>
                            </div>
                            <div class="col-sm-11 col-12">
                                <p class="bannerContent text-sm-center text-lg-start"> {{businessData.banner_text}}</p>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-lg-5 col-6">
                                        <button class="freeTrial float-sm-end float-lg-none mt-5"> {{businessData.button_text}} </button>
                                    </div>
                                    <div class="col-lg-5 col-6">
                                        <button class="contactSales mt-5"> Contact Sales </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 mt-sm-5 mt-lg-0">
                        <img :src="businessData.banner_image" alt="" class="img-fluid" />
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-sm-12">
                        <p class="mb-4 lh-4 fs-5 text-capitalize explore-text">Explore our virtual queuing system, scheduling management system, deals, quotation. <a href="#" class="fw-bold">Watch Video</a></p>
                    </div>
                    <div class="col-12">
                        <div class="row cardSection">
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="growBox"></div>
                                <div class="my-4">
                                    <h6 class="fw-bold">Grow your business</h6>
                                    <p> Get more visibility, attract new clients and keep your existing clients by listing your business and using our marketing tools such as e-mail campaigns.</p>
                                    <a href="#" class="pe-2"> Learn more</a>
                                    <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/arrow_right.png" class="img-fluid" style="width: 20px; height:10px" />
                                    </div>
                            </div>

                            <div class="col-lg-4 col-md-6 col-sm-12 mt-sm-5 mt-md-0">
                                <div class="getBookedBox"></div>
                                <div class="my-4">
                                    <h6 class="fw-bold">Get booked</h6>
                                    <p> Explore features like flex booking, no-show protection, cancellation policies and simple calendar management.</p>
                                    <a href="#" class="pe-2"> Learn more</a>
                                    <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/arrow_right.png" class="img-fluid" style="width: 20px; height:10px" />
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 col-sm-12 mt-sm-5 mt-lg-0">
                                <div class="noMoreWaitingBox"></div>
                                    <!-- <img src="@/assets/images/Business_page_img/noMoreWaiting.png" class="img-fluid" alt="No more waiting-Image"> -->
                                <div class="my-4">
                                    <h6 class="fw-bold">Last-minute Appointment </h6>
                                    <p> No more waiting! Grow your business, get more clients using our real-time virtual queuing managememt system for same-day services.</p>
                                    <a href="#" class="pe-2"> Learn more</a>
                                    <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/arrow_right.png" class="img-fluid" style="width: 20px; height:10px" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="contactSection">
            <div class="container">
                <div class="row justify-content-around">
                    <div class="col-12 mb-5">
                        <h4 class="text-white text-center mt-2 mb-4 my-sm-4 fs-2">Gain more exposure for your business, we use data to analyze your business and market to the right audience</h4>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3">
                        <div class="text-center bg-white rounded contact-box">
                            <img src="@/assets/images/Business_page_img/message.jpg" alt="" class="img-fluid" />
                            <p>Text messages</p>
                        </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3">
                        <div class="text-center bg-white rounded contact-box">
                            <img src="@/assets/images/Business_page_img/email.jpg" alt="" class="img-fluid" />
                            <p>E-mails</p>
                        </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3 mt-4 mt-sm-0">
                        <div class="text-center bg-white rounded contact-box">
                            <img src="@/assets/images/Business_page_img/notification.jpg" alt="" class="img-fluid" />
                            <p>Notifications</p>
                        </div>
                    </div>
                    <div class="mb-3"></div>
                </div>
            </div>
        </section>

        <section style="background-color: #f5f5f5">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 order-sm-2 mt-sm-5 walk-in-img">
                        <img src="@/assets/images/Business_page_img/service.jpg" alt="" class="img-fluid" />
                    </div>

                    <div class="col-lg-6 ps-sm-5  walk-in-content">
                        <div class="row">
                            <div class="col-12 ps-lg-0">
                                <h4 class="fw-bold mb-3">Online booking and walk-in services</h4>
                            </div>

                            <div class="row mt-2">
                                <div class="col-1 ps-lg-0">
                                    <fa class="checkcircle m-0 float-end float-lg-start" :icon="['fas', 'check']" />
                                </div>
                                <div class="col-11 p-0">
                                    <span class="walkInText text-center">Add services</span>
                                </div>
                            </div>

                            <div class="row mt-2">
                                <div class="col-1 ps-lg-0">
                                    <fa class="checkcircle m-0 float-end float-lg-start" :icon="['fas', 'check']" />
                                </div>
                                <div class="col-lg-10 col-sm-8 col-11 p-0">
                                    <span class="walkInText text-center">Deals - Attract clients by reducing the service price and you may also give free consultations at no cost.</span>
                                </div>
                            </div>

                            <div class="row mt-2">
                                <div class="col-1 ps-lg-0">
                                    <fa class="checkcircle m-0 float-end float-lg-start" :icon="['fas', 'check']" />
                                </div>
                                <div class="col-11 p-0">
                                    <span class="walkInText text-center">You can chat easily when clients request a quotation.</span>
                                </div>
                            </div>
                            <div class="ps-lg-0">
                                <button class="listBusiness mt-4" v-if="$storage.getStorageSync('business') && $storage.getStorageSync('business').has_business == 1" v-on:click="$router.push({ name: 'BusinessDashboard'})"> <fa icon="chart-line" style="color:white" /> Manage business </button>
                                <button class="listBusiness mt-4" v-else v-on:click="$router.push({ name: 'AddBusiness'})"> <fa icon="plus" /> List my business </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 mb-5 flexible_payment">
                        <h4 class="col-12 fw-bold mb-4">A flexible payment plan that fits all businesses</h4>
                        <div class="col-12">
                            <div class="row mt-2">
                                <div class="col-1">
                                    <fa class="checkcircle" :icon="['fas', 'check']" />
                                </div>
                                <div class="col-11">
                                    <label> Monthly subscription or pay per transactions </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="row mt-2">
                                <div class="col-1">
                                    <fa class="checkcircle" :icon="['fas', 'check']" />
                                </div>
                                <div class="col-11">
                                    <label> Get paid for No-show </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="row mt-2">
                                <div class="col-1">
                                    <fa class="checkcircle" :icon="['fas', 'check']" />
                                </div>
                                <div class="col-11">
                                    <label> Earn more using our virtual queuing management systems </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="row mt-2">
                                <div class="col-1">
                                    <fa class="checkcircle" :icon="['fas', 'check']" />
                                </div>
                                <div class="col-11">
                                    <label> Online payments through our app </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="row mt-2">
                                <div class="col-1">
                                    <fa class="checkcircle" :icon="['fas', 'check']" />
                                </div>
                                <div class="col-11">
                                    <label> Get more clients and increase your team </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="row mt-2">
                                <div class="col-1">
                                    <fa class="checkcircle" :icon="['fas', 'check']" />
                                </div>
                                <div class="col-11">
                                    <label> Get paid quickly </label>
                                </div>
                            </div>
                        </div>
                        <!-- <a href="#" style="color: #6e317a" class="pe-2 mt-3"> Learn more</a> -->
                        <!-- <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/how_it_works/arrow_right.png" class="img-fluid" style="width: 20px; height:10px" /> -->
                    </div>

                    <div class="col-lg-6 mt-sm-5 mt-lg-0">
                        <img src="@/assets/images/Business_page_img/flexible-payment1.jpg" alt="" class="img-fluid float-lg-end" />
                    </div>
                </div>
            </div>
        </section>

        <section style="background-color: #f5f5f5" >
            <div class="container">
                <div class="row">
                    <div class="col-12 mb-3">
                        <h2 class="fw-bold text-center marketingTitles">Frequently Asked Questions</h2>
                    </div>

                    <div class="col-lg-6 col-sm-12 mb-3">
                        <div class="accordion" id="accordionExample1">
                            <div class="accordion-item border-0">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        <strong>Do you offer a free trial?</strong>
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                                    <div class="accordion-body">
                                        Yes, we do offer a free trial, and no credit card is needed
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-12 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item border-0">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseOne">
                                        <strong>Does signup require a credit card?</strong>
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        You will get a free trial when you sign up; no credit cards are needed.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-12 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item border-0">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseOne">
                                        <strong>Can I use both queuing and scheduling management systems?</strong>
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Yes, you may assign a team member to use the queuing or scheduling.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-12 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item border-0">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseOne">
                                        <strong>How do I get more clients?</strong>
                                    </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Our mission is to keep your calendar and queue full. We will market and drive clients to patronize you using our marketing strategies
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-12 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item border-0">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseOne">
                                        <strong>When do I get my money?</strong>
                                    </button>
                                </h2>
                                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Immediately you complete a service, automatically your money is reflected in your business dashboard
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-12 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item border-0">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseOne">
                                        <strong>How many professionals or employees can I add to my team?</strong>
                                    </button>
                                </h2>
                                <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        You may add any amount of professionals
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-12 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item border-0">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseOne">
                                        <strong>Do you have a subscription plan?</strong>
                                    </button>
                                </h2>
                                <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Our plan fits any size of business, we have a monthly subscription
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-12 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item border-0">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseOne">
                                        <strong>Is this company an American company?</strong>
                                    </button>
                                </h2>
                                <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Yes, Ondaq was founded in Charlotte, North Carolina
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-12 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item border-0">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseOne">
                                        <strong>How do I reach OnDaQ support?</strong>
                                    </button>
                                </h2>
                                <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Our support team can be reached via email at support@ondaq.com or via chat when it’s available
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-12 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item border-0">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapseOne">
                                        <strong>Do you have a subscription plan?</strong>
                                    </button>
                                </h2>
                                <div id="collapse10" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Yes, our plan fits any size of business, we have a monthly subscription plan and Pay As You Earn.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-12 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item border-0">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-expanded="false" aria-controls="collapseOne">
                                        <strong>Do you have a free trial?</strong>
                                    </button>
                                </h2>
                                <div id="collapse11" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Yes, we offer 30-day free trial, and no credit card is needed.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-12 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item border-0">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse12" aria-expanded="false" aria-controls="collapseOne">
                                        <strong>Do you have mobile applications?</strong>
                                    </button>
                                </h2>
                                <div id="collapse12" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Yes we do, although we will first launch Ondaq web application, then launch the mobile application 30 days after the soft launch.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-12 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item border-0">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse13" aria-expanded="false" aria-controls="collapseOne">
                                        <strong>How do I get more clients?</strong>
                                    </button>
                                </h2>
                                <div id="collapse13" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Ondaq uses data to target the right audience. And you will get free marketing tools
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-12 mb-3">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item border-0">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse14" aria-expanded="false" aria-controls="collapseOne">
                                        <strong>I live in New York,, will Ondaq work here?</strong>
                                    </button>
                                </h2>
                                <div id="collapse14" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Yes, Ondaq is an online platform and a marketplace. We will be launching in Charlotte, North Carolina.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import axios from 'axios'
    // import $ from 'jquery'
    // import AOS from 'aos'
    // import 'aos/dist/aos.css'

    export default {
        data() {
            return {
                businessData:[]
            }
        },
        methods:{
            /*playit(){
                var ctrlVideo = document.getElementById("video");
                ctrlVideo.play();
            }*/
            bannerData(){
                let thiss = this
                axios.get('bannerData/OnDaQ Business').then(function (response) {
                    if (response.data.status == 'success') {
                        thiss.businessData = response.data.data[0]
                        console.log(thiss.businessData)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 422) {
                            thiss.toast.error(error.response.data.message);
                        }else if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
                            thiss.toast.error(error.response.data.message);
                        }
                    }
                });
            }
        },
        mounted() {
            // AOS.init()
            // $('.video').parent().click(function () {
            //     if($(this).children(".video").get(0).paused){
            //         $(this).children(".video").get(0).play();
            //         $(this).children(".playpause").fadeOut();
            //     }else{
            //         $(this).children(".video").get(0).pause();
            //         $(this).children(".playpause").fadeIn();
            //     }
            // });
            this.bannerData()
        }
    }
</script>
<style scoped>

    *{
        box-sizing: border-box;
    }
    body{
        font-family: 'Roboto';
        font-style: normal;

    }
    .bannerSection{
        padding: 80px 0;
        background-color: #e6fcef
    }
    .accordion-button ,.accordion-body{
        font-size: 18px;
    }
    .accordion-button:not(.collapsed){
        color: #000;
    }
    .bannerBlock .heading{
        font-weight: 700;
        font-size: 30px;
    }
    .bannerBlock .dotBullet{
        font-size: 9px;
    }
    .bannerBlock .dotBullet p{
        font-size: 14px;
        font-weight: 600;
        margin-left: 10px;
        margin-bottom: 8px;
    }
    .cardSection .growBox{
        background: url(./../../assets/images/Business_page_img/grow.jpg) ;
        height: 200px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .cardSection .getBookedBox{
        background: url(./../../assets/images/Business_page_img/getBooked.jpg) ;
        height: 200px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .cardSection .noMoreWaitingBox{
        background: url(./../../assets/images/Business_page_img/noMoreWaiting.jpg) ;
        height: 200px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

    }
    .contactSection{
        background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.45) 27.05%, rgba(0, 0, 0, 0.08) 60.74%), url(./../../assets/images/Business_page_img/timeTool.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
    }
    .contactSection .contact-box{
        padding: 30px 10px;
    }
    .contactSection img{
        width: 70px;
    }
    .contactSection p{
        margin-top: 25px;
        margin-bottom: 0;
        font-weight: 500;
        font-size: 18px;
    }
    .listBusiness{
        border: 0;
        padding: 8px 30px;
        color: #fff;
        background-color: #6e317a;
        border-radius: 5px;
    }

    .freeTrial{
        /* position: relative; */
        font-size: 17px;
        width: 80%;
        border: 0;
        padding: 8px 0px;
        color: #fff;
        background-color: #6e317a;
        border-radius: 5px;
    }
    .contactSales{
        /* position: relative; */
        font-size: 17px;
        width: 80%;
        border: 0;
        padding: 8px 0px;
        color: #FFFFFF;
        background: #000000;
        border-radius: 5px;
    }
    .walk-in-content{
        position: absolute;
        right: 0;
    }
    .svg-inline--fa{
        color: #572062;
    }
    @media screen and (max-width: 1200px)
    {
        .walk-in-content{
            margin-top: 40px !important;
        }
        .walkInText{
        font-size: 15px;
        }
        .freeTrial{
            width: 89%;
        }
        .contactSales{
            width: 89%;
        }
    }
    @media screen and (max-width: 1055px)
    {
        .walk-in-content{
            margin-top: 0 !important;
        }
    }
    @media screen and (max-width: 991px)
    {
        .freeTrial{
            font-size: 16px;
            width: 50%;
        }
        .contactSales{
            font-size: 16px;
            width: 50%;
        }
        .bannerBlock .heading{
            font-size: 26px;
            text-align: center;
        }
        .bannerBlock .bannerContent{
            line-height: 2;
        }
        .walk-in-content{
            position: static;
            left: 0;
            margin-top: 0 !important;
        }
        .svg-inline--fa{
            margin-left: 20px;
        }

    }
    @media screen and (max-width: 767px)
    {
        .cardSection .growBox, .getBookedBox, .noMoreWaitingBox{
            height: 280px !important;
        }

        .freeTrial{
            width: 67%;
            font-size: 16px;
        }
        .contactSales{
            width: 67%;
            font-size: 16px;
        }
        .svg-inline--fa{
            margin-left: 10px;
        }
    }
    @media screen and (max-width: 575px)
    {
        .bannerSection{
        padding: 60px 0;
    }
        .freeTrial{
            width: 60%;
            float: right;
            margin-top: 25px !important;
            margin-bottom: 40px;
            font-size: 12px;
        }
        .contactSales{
            width: 60%;
            margin-bottom: 40px;
            margin-top: 25px !important;
            font-size: 12px;
        }
        .explore-text{
            font-size: 16px !important;
        }
        .walk-in-img{
            order: 2;
            margin-top: 20px;
        }
        .bannerBlock .bullet{
            display: none;
        }
        .bannerBlock .bannerContent{
            text-align: center;
        }
        .flexible_payment label{
            font-size: 14px;
        }
        .svg-inline--fa{
            font-size: 13px;
            margin-left: 10px;
        }
        .contactSection .contact-box{
           padding: 30px 10px;
        }
        .cardSection .growBox, .getBookedBox, .noMoreWaitingBox{
            height: 250px !important;
        }
    }
@media screen and (max-width: 490px)
    {
        .cardSection .growBox, .getBookedBox, .noMoreWaitingBox{
            height: 220px !important;
        }
        .freeTrial{
            width: 70%;
        }
        .contactSales{
            width: 70%;
        }
        .svg-inline--fa{
            font-size: 13px;
            margin-left: 5px;
        }
        .listBusiness{
            font-size: 12px;
        }
        .flexible_payment h4{
            text-align: center;
            margin-bottom: 20px;
        }
        .accordion-button {
            font-size: 14px;
            padding: 12px;
        }
        .contactSection h4{
            font-size: 20px !important;
        }
        .contactSection img{
            width: 50px;
        }
        .contactSection p{
            margin-top: 25px;
            margin-bottom: 0;
            font-weight: 500;
            font-size: 18px;
        }
        .accordion-button {
            font-size: 14px;
            padding: 12px;
        }
    }
</style>